import React from 'react';
import Main from '../../containers/Layout';
import accountantsPageContent from '../../../content/pages/marketing/accountants.yml';
import AccountantsContainer from '@/containers/Accountants';

export interface AccountantsPageContent {
  viewport1: {
    tickPlates: {
      item: string;
    }[];
    title1: string;
    title2: string;
    description: {
      item: string;
    }[];
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    label: string;
    benefitCards: {
      label: string;
      text: string;
      image: string;
    }[];
  };
  viewport3: {
    label: string;
    leftCardLabel1: string;
    leftCardLabel2: string;
    leftCardText: string;
    rightCardLabel1: string;
    rightCardLabel2: string;
    rightCardText: string;
  };
  viewport4: {
    label: string;
    description: string;
    payByLinkCards: {
      count: string;
      label: string;
      text: string;
      image: string;
    }[];
  };
  viewport5: {
    label: string;
    title1: string;
    title2: string;
    description1: string;
    description2: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport6: {
    title1: string;
    title2: string;
    title3: string;
    description: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport7: {
    title: string;
    description: string;
    howToCards: {
      count: string;
      text: string;
      image: string;
    }[];
  };
  viewport8: {
    bannerLabel: string;
    descriptionItems: {
      item: string;
    }[];
    button: { label: string; url: string };
  };
}
const Accountants: React.FunctionComponent = () => {
  const {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
  } = accountantsPageContent as unknown as AccountantsPageContent;
  return (
    <Main>
      <AccountantsContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
          viewport7,
          viewport8,
        }}
      />
    </Main>
  );
};

export default Accountants;
