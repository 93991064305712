import React from 'react';
import styled from 'styled-components';
import { AccountantsPageContent } from '@/pages/marketing/accountants';
import FirstViewport from '@/components/FirstViewport';
import {
  Body4,
  H1,
  H3,
  P,
  SecondaryP,
  StrongInitial,
  Subtitle2,
} from '@/components/Typography';
import BlueTickIcon from '../assets/icons/blueTick.svg';
import LinkIcon from '../assets/icons/link.svg';
import RefreshIcon from '../assets/icons/refresh.svg';
import Vp1Image from '../assets/img/accountantsVp1.png';
import Vp5Image from '../assets/img/accountantsVp5.png';
import Vp6Image from '../assets/img/accountantsCode.png';
import invoiceCardLeft from '../assets/img/invoiceCardLeft.png';
import invoiceCardRight from '../assets/img/invoiceCardRight.png';
import ButtonDefault from '@/components/ButtonDefault';
import CarouselCard from '@/components/CarouselCard';
import Section from '@/components/Section';
import PayByLinkCard from '@/components/PayByLinkCard';
import FirstViewportMirrored from '@/components/FirstViewportMirrored';
import HowToCard from '@/components/HowToCard';
import Banner from '@/components/Banner';
import bannerBg from '../assets/img/accountantsBanner.png';
import TickedItems from '@/components/TickedItems';

const FirstViewportWrapper = styled.div`
  background: linear-gradient(123.46deg, #fbfbfd 45.14%, #e7edf3 100.49%);
`;

const VpWrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(194, 206, 219, 0.5) -46.34%,
    rgba(244, 247, 249, 0.5) 26.53%,
    rgba(244, 247, 249, 0) 100%
  );
`;

const TickPlatesWrapper = styled.div`
  display: flex;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const TickPlate = styled.div`
  display: flex;
  align-items: center;
  background-color: #e7edf3;
  border-radius: 31px;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  @media (max-width: 1200px) {
    margin-bottom: 1rem;
  }
`;

const BlueTick = styled(BlueTickIcon)`
  margin-right: 0.5rem;
`;

const Description = styled.div`
  margin: 2rem 0;
`;

const ViewportsWrapper = styled.div`
  background: linear-gradient(
    31.87deg,
    rgba(255, 255, 255, 0.2) 20.76%,
    rgba(228, 230, 240, 0.2) 82.32%
  ); ;
`;

const SecondViewport = styled(Section)`
  background-color: #ffff;
  padding-bottom: 0;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const Label = styled(H3)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const ThirdViewport = styled(Section)`
  background-color: #ffff;
  padding-bottom: 0;
`;

const InvoiceCardsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const InvoiceCard = styled.div`
  border-radius: 20px;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 5rem;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1200px) {
    margin-bottom: 2rem;
    flex-grow: 1;
    flex-basis: 100%;
    margin-right: 0;
  }
`;

const InvoceCardLabel = styled(Subtitle2)`
  margin: 1rem 0;
`;

const InvoiceCardLeft = styled(InvoiceCard)`
  background: linear-gradient(341.28deg, #c2cedb -38.66%, #f4f7f9 130.75%);
`;
const InvoiceCardRight = styled(InvoiceCard)`
  background: linear-gradient(128.69deg, #14bd89 11.83%, #47ef90 86.41%);
`;

const InvoiceCardImg = styled.img`
  align-self: flex-end;
  width: 80%;
  @media (max-width: 1200px) {
    border-bottom-right-radius: 20px;
  }
`;

const InvoiceCardTop = styled.div`
  padding: 3rem 3rem 0 3rem;
  @media (max-width: 1200px) {
    padding: 1rem 1rem 0 1rem;
  }
`;

const FourthViewport = styled(Section)`
  background-color: #fff;
`;

const VpDescription = styled(P)`
  text-align: center;
  margin-top: 1rem;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const PayByLinkCardsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
`;

const StyledStrong = styled(StrongInitial)`
  margin-top: 2rem;
`;

const SeventhViewport = styled(Section)`
  background-color: #fff;
`;

const CenterH1 = styled(H1)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const HowToCardsWrap = styled.div`
  display: flex;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const EighthViewport = styled(Section)`
  background-color: #fff;
`;

interface AccountantsProps {
  content: AccountantsPageContent;
}

const AccountantsContainer: React.FunctionComponent<AccountantsProps> = ({
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
  },
}) => (
  <>
    <FirstViewportWrapper>
      <FirstViewport withRightPadding img={Vp1Image}>
        <TickPlatesWrapper>
          {viewport1.tickPlates.map((el) => (
            <TickPlate key={el.item}>
              <BlueTick />
              <Body4>{el.item}</Body4>
            </TickPlate>
          ))}
        </TickPlatesWrapper>
        <H1>
          <span className="accent-text">{viewport1.title1}</span>
          {` `}
          {viewport1.title2}
        </H1>
        <Description>
          <TickedItems items={viewport1.description} />
        </Description>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </FirstViewport>
    </FirstViewportWrapper>
    <ViewportsWrapper>
      <SecondViewport>
        <Label>{viewport2.label}</Label>
        <CardWrapper>
          {viewport2.benefitCards.map((item) => (
            <CarouselCard
              key={item.label}
              flexBasis="25%"
              marginBottom="2rem"
              title={item.label}
              text={item.text}
              cardImgSrc={item.image}
            />
          ))}
        </CardWrapper>
      </SecondViewport>
    </ViewportsWrapper>
    <ThirdViewport>
      <Label>{viewport3.label}</Label>
      <InvoiceCardsWrapper>
        <InvoiceCardLeft>
          <InvoiceCardTop>
            <RefreshIcon />
            <InvoceCardLabel>
              {viewport3.leftCardLabel1}
              {` `}
              <span className="accent-text">{viewport3.leftCardLabel2}</span>
            </InvoceCardLabel>
            <P>{viewport3.leftCardText}</P>
          </InvoiceCardTop>

          <InvoiceCardImg src={invoiceCardLeft} alt="code screenshot" />
        </InvoiceCardLeft>
        <InvoiceCardRight>
          <InvoiceCardTop>
            <LinkIcon />
            <InvoceCardLabel>
              <span className="accent-text-white">
                {viewport3.rightCardLabel1}
              </span>
              {` `}
              {viewport3.rightCardLabel2}
            </InvoceCardLabel>
            <P className="accent-text-white">{viewport3.rightCardText}</P>
          </InvoiceCardTop>

          <InvoiceCardImg src={invoiceCardRight} alt="code screenshot" />
        </InvoiceCardRight>
      </InvoiceCardsWrapper>
    </ThirdViewport>
    <FourthViewport>
      <Label>{viewport4.label}</Label>
      <VpDescription>{viewport4.description}</VpDescription>
      <PayByLinkCardsWrap>
        {viewport4.payByLinkCards.map((item) => (
          <PayByLinkCard
            key={item.label}
            number={item.count}
            label={item.label}
            text={item.text}
            imageSrc={item.image}
          />
        ))}
      </PayByLinkCardsWrap>
    </FourthViewport>
    <VpWrapper>
      <FirstViewport imgWidth="50%" withRightPadding img={Vp5Image}>
        <SecondaryP className="accent-text-gray">{viewport5.label}</SecondaryP>
        <H1>
          <span className="accent-text">{viewport5.title1}</span>
          {` `}
          {viewport5.title2}
        </H1>
        <StyledStrong>
          {viewport5.description1}
          {viewport5.description2}
        </StyledStrong>
        <Description>
          <TickedItems items={viewport5.descriptionItems} />
        </Description>
      </FirstViewport>
      <FirstViewportMirrored imgWidth="50%" withRightPadding img={Vp6Image}>
        <H1>
          {viewport6.title1}
          <span className="accent-text"> {viewport6.title2}</span>
          {` `}
          {viewport6.title3}
        </H1>
        <StyledStrong>{viewport6.description}</StyledStrong>
        <Description>
          <TickedItems items={viewport6.descriptionItems} />
        </Description>
      </FirstViewportMirrored>
    </VpWrapper>

    <SeventhViewport>
      <CenterH1>{viewport7.title}</CenterH1>
      <VpDescription>{viewport7.description}</VpDescription>
      <HowToCardsWrap>
        {viewport7.howToCards.map((item) => (
          <HowToCard
            key={item.count}
            number={item.count}
            text={item.text}
            imageSrc={item.image}
          />
        ))}
      </HowToCardsWrap>
    </SeventhViewport>
    <EighthViewport>
      <Banner
        bgSrc={bannerBg}
        label={viewport8.bannerLabel}
        descriptionItems={viewport8.descriptionItems}
        buttonUrl={viewport8.button.url}
        buttonLabel={viewport8.button.label}
      />
    </EighthViewport>
  </>
);

export default AccountantsContainer;
